
import Vue from 'vue';
import Http, { HttpError } from '@/utils/Http';
import { empty } from '@/utils/helpers';
import intl from '@/utils/intl';

export default Vue.extend({
  name: 'Dashboard',
  data() {
    return {
      loading: false,
      dashboard: {} as Record<string, string>
    }
  },
  methods: {
    setLabelName(name: string): string {
      return intl.t(`views.dashboard.${name}Label`);
    },
    getValue(value: string): string {
      if (!empty(this.dashboard)) {
        return this.dashboard[value] || '0';
      }
      return '';
    },
    async getDashboard(): Promise<void> {
      this.loading = true;
      try {
        const response = await (new Http()).get(`/v1/user/dashboard`);
        if (!empty(response.data)) {
          this.dashboard = response.data;
        }
      } catch (error) {
        const err = (error as HttpError).response?.data.error;
        this.$root.$children[0].$children[0].setFlash('error', err.message);
      }
      this.loading = false;
    }
  },
  async mounted() {
    await this.getDashboard();
  }
});
